<template>
    <div class="horizontal-wrapper" style="flex: 0 0 auto">
        <md-field style="flex: 1 0 0 ">
            <label for="">Element</label>
            <md-select v-model="selectedElement">

                <md-option v-for="item in flangeItemsNames" :key="item" :value="item">{{ item }}</md-option>
            </md-select>
        </md-field>
        <div style="flex: 0 0 100px " class="vertical-wrapper">
            <div></div>
            <p style="font-weight: bold;">
                {{ elementScrewLength }}mm
            </p>
            <div></div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default{
    name: 'flange-element',
    props: { value: String  },

    computed:{ 
        ...mapGetters(['getFlangeItems', 'getSelectedFlangeSize']),

        flangeItemsNames(){
            return ['-', ...Object.keys(this.getFlangeItems)]
        },
        selectedElement:{
            set(v){
                this.$emit('input', v)
            },
            get(){
                return this.$props.value
            }
        },
        elementScrewLength(){
            const e = this.selectedElement;
            const size = this.getSelectedFlangeSize
            if(e == '-')
                return 0

            return this.getFlangeItems[e][size]
        }

    }
    
}
</script>