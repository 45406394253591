<template>
    <div>
        <md-dialog :md-active.sync="show">
            <md-dialog-title >Schrauben Längen</md-dialog-title>
            <div class="content" style="overflow: scroll">
                <div>
                    <md-button v-on:click="loadTable">reload</md-button>

                    <md-button class="md-primary" v-on:click="addColumn">neue Größe</md-button>
                    <md-button class="md-primary" v-on:click="editColumn">Größe bearbeiten</md-button>
                    <md-button class="md-accent" v-on:click="deleteColumn">Größe entfernen</md-button>

                </div>
                <div ref="table"></div>
            </div>
            <div>
                <md-dialog-actions>
                    <md-button class="md-primary" @click="show = false">Close</md-button>
                    <md-button class="md-primary" @click="save">Save</md-button>
                </md-dialog-actions>
            </div>
        </md-dialog>
    </div>
</template>
<script>

import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars
import { mapGetters } from "vuex";

export default {
    name: 'edit-view',
    watch:{
        show(){
            if(!this.show)
                this.$router.go(-1)
        }
    },
    methods:{
        save(){
            const table = this.spreadsheet.getData()
            const headers = this.spreadsheet.getHeaders().split(',')
            const tableObject = {}
            table.forEach(row => {

                
                const key = row[0];
                tableObject[key] = {}

                row.forEach((e, i) => {
                    if(i == 0)
                        return
                    tableObject[key][headers[i]] = e;
                })

            })
            const pw = prompt('Passwort:', '')
            fetch(`${this.$hostname}/table/edit`,{ 
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({pw: pw, table: tableObject})       
            }).then(e => {
                if(e.status == 200){

                    this.$store.dispatch('get-flange-items')
                    this.$router.push({name: 'home'})
                }

            })


        },
        onselection(html, x, y){

            this.x = x;
            this.y = y;

        },
        loadTable(){
            const columns = [{title: 'Bauteil', width: 120, type:'text' } , ...this.getFlangeSizes.map(e => { return { title: e.name, width: 70, type:'numeric'}})]
            const flangeSizes = this.getFlangeSizes.map(e => e.name);

            const data = Object.keys(this.getFlangeItems).map(key => {
                const item = this.getFlangeItems[key];
                return [key, ...flangeSizes.map(e => item[e]? item[e]: 0)]
            })

            this.$refs['table'].innerHTML = ''
            this.spreadsheet = jexcel(this.$refs['table'], {
                data,
                columns,
                onselection: this.onselection
            })
            
        },
        addRow(){
            const name = prompt('Neues Element:')
            
            const row = [name, ...this.getFlangeSizes.map(() => 0)]
            this.spreadsheet.insertRow(row)
        },
        addColumn(){
            const name = prompt('Neues Größe:')
            const index = this.getFlangeSizes.length
            this.spreadsheet.insertColumn(0, index, false, {title: name, width: 70, type:'numeric'})
            
        },
        editColumn(){
            const x = this.x;

            if(x == 0)
                return 

            const header = this.spreadsheet.getHeader(x)
            const name = prompt('Größe:', header)
            this.spreadsheet.setHeader(x, name)
        },
        deleteColumn(){
            const x = this.x;

            if(x == 0)
                return 
            const header = this.spreadsheet.getHeader(x)

            const confirmed = confirm(`Größe ${header} entfernen`)
            if(confirmed){
                this.spreadsheet.deleteColumn(x)
            }
                

            
        },
    },
    computed:{
        ...mapGetters(['getFlangeItems', 'getFlangeSizes']),

    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.show = true;

            

            vm.$nextTick(() => {
                //vm.loadTable();

                setTimeout( vm.loadTable, 500)
               
            })
        })
    },
    data(){
        return {
            show:false,
            table: [],
            spreadsheet: 0,


            x: 0, y: 0,
        }
    }
}
</script>