
import Vue from 'vue'
import Vuex from 'vuex'
import { v4 as uuidv4 } from 'uuid';


Vue.use(Vuex)

const store = function createStore() {
    let userToken = Vue.prototype.$cookies.get('authentication');

    const flangeSize = Vue.prototype.$cookies.get('flange-size');

    const hostname = Vue.prototype.$hostname;

    if (!userToken)
        userToken = ''

    return new Vuex.Store({
        state: () => ({
            token: userToken,
            user: { id: -1, name: '', email: '' },

            admin: 0,


            flangeItems: [],

            flangeSizes: [],
            selectedFlangeSize: flangeSize? flangeSize: 0,
            

        }),
        mutations: {
            'set-token': function (state, token) {
                Vue.prototype.$cookies.set('authentication', token)
                state.token = token;

            },
            
            'set-flange-items': function (state, items){
                state.flangeItems = items
            },
            'set-flange-sizes' :function(state, items){
                state.flangeSizes = []
                items.forEach(e => state.flangeSizes.push(e))
            },
            'set-selected-flange-size': function(state, size){
                state.selectedFlangeSize = size
            }
            
        },
        actions: {
            
            'get-flange-items': function({ commit, state }){
                return new Promise((resolve) => {

                    fetch(`${hostname}/table/get`, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }).then(async e => {
    
                        if(e.status == 200){
                            const items = await e.json()
                            commit('set-flange-items', items)
    
    
                            // extract flange sizes from first item
                            // and set selected flange item to first if 0
                            const flangeSizes = []
                            Object.keys(items).forEach(key => {
                                Object.keys(items[key]).forEach((flangeSize, i) => {
                                    if(!flangeSizes.some(e => e.name == flangeSize))
                                        flangeSizes.push({name: flangeSize, key: uuidv4() })
    
                                    if(state.selectedFlangeSize == 0 && i == 0){
                                        commit('set-selected-flange-size', flangeSize)
                                    }
                                })
                            })
                            commit('set-flange-sizes', flangeSizes)
                            resolve()
                        }   
                    })
                })

               
            }

        },
        getters: {
            token(state) {
                return state.token;
            },
            getFlangeItems(state){
                return state.flangeItems
            },
            getFlangeSize( state ){
                return state.flangeSizes
            },

            getFlangeSizes(state){
                return state.flangeSizes
            },
            getSelectedFlangeSize(state){
                return state.selectedFlangeSize
            }
        }
    })
}
export default store;