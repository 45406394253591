<template>
    <div class="vertical-wrapper">
        <md-field>
            <label for="">Flanschgröße</label>
            <md-select v-model="seletedFlangeSize" >
                <md-option v-for="item in getFlangeSizes" :key="item.key" :value="item.name">{{ item.name }}</md-option>
            </md-select>
        </md-field>


        <h4>Flasch Elemente</h4>
        <div class="vertical-wrapper" style="flex: 1 0 0; overflow-y: scroll;">

            
            <flange-element 
                v-for="e in flangeItems" 
                :key="e.key"
                v-model="e.item"
                v-on:input="changeFlangeItem"
                
            ></flange-element>

            <div class="horizontal-wrapper">
                <div></div>
                <md-button class="md-accent" style="font-size: 24pt;" v-on:click="removeFlangeItem">-</md-button>
                <div></div>
                <md-button class="md-primary" style="font-size: 24pt;" v-on:click="addFlangeItem">+</md-button>
                <div></div>

            </div>
        </div>

        <h4>Schraubenlänge: {{ sumScrewLength }}mm</h4>


        <router-view></router-view>
       
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import flangeElementVue from '../components/flange-element.vue'

export default{
    name: 'home-view',
    components: {
        'flange-element':flangeElementVue   
    },
    computed: { 
        ...mapGetters(['getFlangeItems', 'getFlangeSizes', 'getSelectedFlangeSize']),

        seletedFlangeSize:{
            set(v){
                this.$store.commit('set-selected-flange-size', v)   
                this.changeFlangeItem()
            },
            get(){
                return this.$store.getters.getSelectedFlangeSize
            }
        },

       
    },
    methods:{
        changeFlangeItem(){
            const size = this.getSelectedFlangeSize;
            const FLANGEITEMS = this.getFlangeItems;

            this.sumScrewLength = this.flangeItems.reduce((arr, e) => {
                const key = e.item;
                // get from all flange items current selected object and size
                // if object or size is not a valid item (number) return 0
                const value = FLANGEITEMS[key] && FLANGEITEMS[key][size]? FLANGEITEMS[key][size]: 0
                return parseFloat(value) + arr
            }, 0)

            this.$cookies.set('flange-size', size )
            this.$cookies.set('flange-items', JSON.stringify(this.flangeItems) )

        },

        addFlangeItem(){
            this.flangeItems.push( {item: '-', key: this.$uuid.v4()})
        },
        removeFlangeItem(){
            this.flangeItems.pop()
        },
        
    },
    data(){

        return{
            flangeItems: [ 
                {item: '-', key: this.$uuid.v4()} ,   
                {item: '-', key: this.$uuid.v4()} 
            ],

            sumScrewLength: 0,
        }
    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.$store.dispatch('get-flange-items').then(() => {
                try{
                    const c = vm.$cookies.get('flange-items')
                    if(!c){
                        throw Error()
                    }
                    vm.flangeItems = c
                    vm.changeFlangeItem()

                }catch(e){
                    vm.flangeItems = [ 
                        {item: '-', key: vm.$uuid.v4()} ,   {item: '-', key: vm.$uuid.v4()} 
                    ]
                }
            
            });

          
            
        })
    }
}
</script>