import VueRouter from 'vue-router'

import homeView from '@/views/home-view.vue'
import EditView from '@/views/edit-view.vue'

export default new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', template: '<p>404</P>', redirect: { name: 'home' }, },
        { path: '/home', name: 'home', component: homeView, children: [
            { path : '/home/edit', name: 'edit-view', component: EditView}
        ] },

        { path: '*', template: '<p>404</P>' } // Not found
    ]
})